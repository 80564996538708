import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Col, ScreenClassRender } from 'react-grid-system';
import { navigate } from 'gatsby';
import classNames from 'classnames/bind';
import moment from 'moment';

import styles from './ItemSmall.module.scss';
import { newMenuActions } from '../../../../actions';
import ScaleImageCell from '../../../misc/ScaleImageCell';
import ArticleTitle from '../../../misc/ArticleTitle';

const cx = classNames.bind(styles);

class ItemSmall extends Component {
  render() {
    const { id, title, thumb_image, release_date, expire_date } = this.props.item;
    const fromDate = moment(release_date).format('YYYY.MM.DD');
    const toDate = expire_date === null ? '상시' : moment(expire_date).format('YYYY.MM.DD');

    return (
      <ScreenClassRender
        render={screenClass => {
          const date = ['xs', 'md'].includes(screenClass)
            ? expire_date === null
              ? `${fromDate} 부터`
              : `${toDate} 까지`
            : `${fromDate} ~ ${toDate}`;

          return (
            <Col xs={6} md={3} className={cx('column')}>
              <div className={cx('item')} onClick={() => this.link(id)}>
                {thumb_image !== null && <ScaleImageCell image={thumb_image.url} ratio={1} />}
                {thumb_image === null && <ScaleImageCell image="https://dummyimage.com/270x135/000/fff" ratio={0.5} />}
                <ArticleTitle title={title} date={date} />
              </div>
            </Col>
          );
        }}
      />
    );
  }

  link = id => {
    const currentId = window.location.search.substring(1);
    if (id !== currentId) {
      this.props.actions.newMenuActions.clear();
    }
    navigate(`/news/new/view/?${id}`);
  };
}

ItemSmall.propTypes = {
  item: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      newMenuActions: bindActionCreators(newMenuActions, dispatch),
    },
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(ItemSmall);
