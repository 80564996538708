import React, { Component } from 'react';

import NewView from 'components/News/New/View';
import NewRelList from 'components/News/New/RelList';

class NewViewPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      location: null,
    };
  }

  componentDidMount() {
    this.setState({
      location: window.location,
    });
  }

  render() {
    const { location } = this.state;
    const id = location !== null ? location.search.substring(1) : null;
    
    return (
      <div>
        {id !== null && (
          <NewView id={id} />
        )}
        <NewRelList />
      </div>
    );
  }
}

export default NewViewPage;
