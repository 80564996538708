import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import { Link } from 'gatsby';
import classNames from 'classnames/bind';
import moment from 'moment';
import _ from 'lodash';
import Ionicon from 'react-ionicons';

import styles from './View.module.scss';
import { newMenuActions } from '../../../actions';

const cx = classNames.bind(styles);

class View extends Component {
  componentDidMount() {
    this.props.actions.newMenuActions.request(this.props.id);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { newMenu } = nextProps;
    return !newMenu.isProcessing && !newMenu.failure;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      this.props.actions.newMenuActions.request(nextProps.id);
    }
  }

  componentWillUnmount() {
    this.props.actions.newMenuActions.clear();
  }

  render() {
    const { newMenu } = this.props;

    const fromDate = moment(newMenu.data.release_date).format('YYYY.MM.DD');
    const toDate = newMenu.data.expire_date === null ? '상시' : moment(newMenu.data.expire_date).format('YYYY.MM.DD');
    let status = '판매 중';
    if (moment().isAfter(newMenu.data.expire_date, 'day')) {
      status = '종료';
    } else if (moment().isBefore(newMenu.data.release_date, 'day')) {
      status = '판매 예정';
    }

    return (
      <ScreenClassRender
        render={screenClass => {
          const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

          return (
            <div className={cx(['container', { mobile: isMobile }])}>
              {!_.isEmpty(newMenu.data) && (
                <Container>
                  <Row>
                    <Col xs={12} md={8} offset={{ md: 2 }}>
                      <div>
                        <div className={cx('meta')}>
                          <span className={cx('status')}>{status}</span>|
                          <span className={cx('date')}>{`${fromDate} ~ ${toDate}`}</span>
                        </div>
                        <div className={cx('title')}>{newMenu.data.title}</div>
                      </div>
                      <img src={newMenu.data.detail_image.url} alt={newMenu.data.title} className={cx('image')} />
                    </Col>
                  </Row>
                </Container>
              )}

              <div className={cx('buttonWrapper')}>
                <Link to="/news/new" className={cx('loadMore')}>
                  <div className={cx('loadMoreText')}>새메뉴 내역</div>
                  <Ionicon icon="md-list" fontSize="17px" />
                </Link>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

/**
 * Define redux settings
 */
const mapStateToProps = state => {
  const { newMenu } = state;
  return {
    newMenu,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      newMenuActions: bindActionCreators(newMenuActions, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
